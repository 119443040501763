.login {
	display: grid;

	.login-section--login {
		grid-column: 1;
		width: auto;
	}

	.login-section--apply {
		grid-column: 2;
		background: none;
		text-align: left;

		a {
			margin-left: 0;
		}
	}
}

@media (max-width: 768px) {
	.login {
		.login-section--apply {
			grid-column: 1;
			grid-row: 2;
		}
	}
}
