.site-footer__header {
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  font-size: 20px;
}

.i--facebook,
.i--instagram,
.i--twitter,
.i--pinterest,
.i--youtube {
  background-color: #f8b6b7;
}

.site-footer a, .site-footer__copyright small {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  font-size: 16px;
}

#footer {
  border-top: 24px solid #f8b6b7;
  background: #000;
}
