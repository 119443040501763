.bar {
  text-align: center;
  margin: 20px auto;

  & > span {
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-style: italic;
    color: #000;
    font-size: 40px;
    text-transform: uppercase;
  }
}
.slider-title {
  overflow: hidden;
}
.slider-caption {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.slider--products {
  margin: 40px auto;

  .bx-wrapper {
    .bx-next {
      background: url("/content/themes/thestylegarage/redesign/images/new-slider-arrows_updated.png") -34px 0;
    }

    .bx-prev {
      background: url("/content/themes/thestylegarage/redesign/images/new-slider-arrows_updated.png");
    }

    .bx-controls-direction a {
      width: 34px;
      height: 34px;
    }

    .bx-pager {
      display: none;
    }
  }
}

.home-article__title {
  color: #000;
  text-align: center;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.home-article__link--read,
.home-article__teaser {
  display: none;
}

@media (max-width: 480px) {
  .bar span { font-size: 28px; }
}
