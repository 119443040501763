.standard-banners--tertiary {
  display: block;

  .bar {
    overflow: visible;
  }

  .standard-banner {
    max-width: 1000px;
    margin: 15px auto;
  }
}

.standard-banner {
  max-width: unset;
}

.main-banner-wrap {
  .bx-prev, .bx-next {
    display: none !important;
  }
}
