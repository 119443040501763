.site-nav__top {
	color: #000;
	font-family: futura-pt, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

@media (max-width: 820px) {
	.site-nav {
		border-top: none;
		border-bottom: none;
	}
}

.nav-toggles {
	background-color: #000;
}

.nav-toggle {
	background-color: transparent;
}
