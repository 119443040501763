@import 'styles/fonts';

$font-thin: 'Futura PT W08 Light';
$font-regular: 'Futura PT W01 Book';
$font-medium: 'Futura PT Medium Italic W08 Rg';
$font-bold: 'Futura PT W08 Bold';

.dev-message {
	display: none;
}

img {
	max-width: 100%;
	height: auto;
}

.main-blog__text {
	margin-top: 8px;
}


/*--- Social Banner Styles */
.social-banner-container {
	display: flex;
	justify-content: center;
}

.social-text {
	display: flex;
	justify-content: flex-start;
	max-width: 1000px;
	margin: auto;
	padding-left: 10px;
	font-family: futura-pt,sans-serif;
	font-weight: 300;
	font-style: italic;
	color: #000;
	font-size: 24px;

	a {
		color: #008CBA;
		margin-left: 4px;
	}
}

.myaccount-nav__link {
	background-color: #ae256f;

	&--selected, &:hover {
		background-color: #7e0144;
	}
}

@import 'styles/login';

@import 'styles/headertop';

@import 'styles/headerbanner';

@import 'styles/homebanners';

@import 'styles/nav';

@import 'styles/scroller';

@import 'styles/footer';

@import 'styles/samples-toggle';
