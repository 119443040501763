@charset "UTF-8";
/**
 * CONTENTS
 *
 * 1 - SETTINGS
 * Color-Reference............A theme-specific list of all available colors.
 *
 * 2 - BASE
 * Headings...................H1–H6 styles for generic versions of those elements.
 *
 * 3 - OBJECTS
 * buttons....................All button styles.
 * icons......................Reusable icons.
 *
 * 4 - COMPONENTS
 * header-top.................Fixed header toolbar.
 * site-menu..................Main site navigation.
 * site-footer................Main site footer.
 * product-filters............Filters for product lists.
 * product-bar................Utility bar for product lists.
 * pagination.................Product list pagination.
 * prod-options-related.......Related products and product option styles on product details pages.
 * product-details............Product detail page styles.
 * product-info...............Product info styles on quick view and product details pages.
 * checkout...................Checkout pages.
 * account-nav................Navigation for My Account section.
 * shopping-cart..............Shopping Cart styles.
 * home-page..................Home page styles.
 * home-articles..............Article list for home page.
 * tabs.......................Tabs.
 *
 * 5 - OVERRIDES
 *
 *
 */
/**
 * 1 - SETTINGS
 */
/* ---------------------------
    #Color-reference
--------------------------- */
/**
 *
 * Primary: 		#ae256f | rgb(234,  25, 141);
 * Primary (Dark): 	#7e0144 | rgb(126,   1,  68);
 * Secondary: 		#9dbb38 | rgb(157, 187,  56);
 *
 * Black: 			#000000 | rgb(  0,   0,   0);
 * 					#333333 | rgb( 51,  51,  51);
 * 					#363636 | rgb( 54,  54,  54);
 * 					#6d6e71 | rgb(109, 110, 113);
 * 					#7e7e7e | rgb(126, 126, 126);
 * 					#d1d2d4 | rgb(209, 210, 212);
 * 					#d6d6d6 | rgb(214, 214, 214);
 * 					#f4f4f4 | rgb(244, 244, 244);
 * White: 			#ffffff | rgb(255, 255, 255);
 *
 */
/**
  * 2 - BASE
  */
/* ---------------------------
     #headings
 --------------------------- */
h1, h1 a {
  color: #ae256f;
}

/**
  * 3 - OBJECTS
  */
/* ---------------------------
     #buttons
 --------------------------- */
.button {
  color: #fff;
  background: #ae256f;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button:hover {
  background: #7e0144;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  background: none;
  text-decoration: underline;
}

.button--quickview {
  background: #ae256f;
}

.button--pending::after {
  background-color: #ae256f;
}

/* ---------------------------
 #icons
--------------------------- */
.i--twitter, .i--facebook, .i--linkedin, .i--pinterest, .i--youtube, .i--wordpress, .i--feed, .i--instagram {
  background-color: #ae256f;
}

.i--twitter-hover {
  background-color: #3dc7f4 !important;
}

.i--facebook-hover {
  background-color: #3b5998 !important;
}

.i--linkedin-hover {
  background-color: #00659b;
}

.i--pinterest-hover {
  background-color: #dc3e47 !important;
}

.i--youtube-hover {
  background-color: #e40202 !important;
}

.i--wordpress-hover {
  background-color: #247ca4;
}

.i--feed-hover {
  background-color: #e08e00;
}

.i--instagram-hover {
  background-color: #773eb1 !important;
}

/**
 * 4 - COMPONENTS
 */
/* ---------------------------
    #header-top
--------------------------- */
.header-top {
  color: #fff;
  background-color: #363636;
}

.header-top--added {
  background-color: #338326;
}

.header-top__link > a {
  color: #fff;
}

/* ---------------------------
    #site-menu
--------------------------- */
.site-nav {
  background-color: #d6d6d6;
  border-top: none;
  border-bottom: none;
}

.sub-nav {
  background: #f4f4f4;
  border-top: none;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    padding: 0;
    background-color: #fff;
    border-bottom: 1px solid #6d6e71;
  }
  .site-nav__top {
    margin: 0;
    border-radius: 0;
  }
  .site-nav__item:hover .site-nav__top, .faux-hover .site-nav__top {
    color: #fff;
    background: #FAB5B6;
  }
  .sub-nav {
    margin-top: 0;
    padding: 0 1px 1px 10px;
    background: #FAB5B6;
    border-top: 1px solid #6d6e71;
    border-radius: 0;
  }
  .sub-nav__inner {
    border-radius: 0;
  }
}

.site-nav__item--black-friday .site-nav__top, .site-nav__item--black-friday:hover .site-nav__top {
  color: #000;
  background-color: #ec0b8e;
}

/* ---------------------------
    #site-footer
--------------------------- */
.site-footer {
  max-width: none;
  margin-top: 45px;
  color: #d1d2d4;
  background-color: #363636;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.site-footer__header {
  border-bottom: none;
}

.site-footer__copyright {
  color: #d1d2d4;
  border-top: 1px solid #6d6e71;
}

.site-footer a {
  color: #d1d2d4;
}

/* ---------------------------
    #product-filters
--------------------------- */
.filters__header {
  padding: 10px;
  font-size: 0.9em;
  background-color: #ae256f;
}

/* ---------------------------
    #product-bar
--------------------------- */
.product-bar {
  background-color: #d6d6d6;
}

/* ---------------------------
    #pagination
--------------------------- */
.pagination__link--active, .pagination__link--active:hover {
  color: #fff;
  background: #ae256f;
}

/* ---------------------------
    #prod-options-related
--------------------------- */
.prod-option:hover, .related-prod:hover {
  border-color: #ae256f;
}

/* ---------------------------
    #product-info
--------------------------- */
.prod-info__name {
  color: #ae256f;
}

/* ---------------------------
    #product-details
--------------------------- */
.product-thumb--selected, .product-thumb--selected:hover {
  border: 1px solid #ae256f;
}

/* ---------------------------
    #checkout
--------------------------- */
.checkout-progress-bar {
  background-color: #d6d6d6;
}

.checkout-progress-selected {
  color: #363636;
}

.checkout-progress {
  color: #6d6e71;
}

/* ---------------------------
    #account-nav
--------------------------- */
.account-nav__list {
  border-top-color: #ae256f;
}

/* ---------------------------
    #shopping-cart
--------------------------- */
.cart-tbl th {
  background-color: #d6d6d6;
  color: #333;
}

/* ---------------------------
    #home-page
--------------------------- */
.slider-caption {
  color: #6d6e71;
  font-size: 1em;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #d1d2d4;
}

.bx-wrapper .bx-controls-direction a {
  width: 31px;
  height: 31px;
}

.bx-wrapper .bx-next {
  background: url("/content/themes/thestylegarage/images/slider-arrows.png") no-repeat -31px 0;
}

.bx-wrapper .bx-prev {
  background: url("/content/themes/thestylegarage/images/slider-arrows.png") no-repeat 0 0;
}

.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -31px 0;
}

.main-banner-wrap .bx-controls-direction, .main-banner-wrap .bx-wrapper .bx-next, .main-banner-wrap .bx-wrapper .bx-prev {
  display: block;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 8px;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 8px;
}

.bar {
  padding: 0;
  color: #6d6e71;
  font-size: 18px;
  text-transform: uppercase;
  background: none;
}

.bar span {
  color: #ae256f;
  text-transform: none;
}

/* ---------------------------
    #home-articles
--------------------------- */
.home-articles {
  margin-bottom: 60px;
}

.home-article__title {
  color: #ae256f;
}

.home-articles__more {
  color: #9dbb38;
}

.fb-feed {
  margin-top: 30px;
}

/* ---------------------------
    #tabs
--------------------------- */
.tabs-nav .tabs-nav--active {
  background: #ae256f;
}

.catalog--primary .catalog__text {
  color: #ae256f;
}

.catalog--secondary .catalog__text {
  color: #9dbb38;
}

.catalog--primary .catalog__text::after {
  background-image: url("/content/themes/thestylegarage/images/arrow-pink.png");
}

.catalog--secondary .catalog__text::after {
  background-image: url("/content/themes/thestylegarage/images/arrow-green.png");
}

/* ---------------------------
    #pecil-banner
--------------------------- */
.page--default .pencil-banner__image {
  display: none;
}

.has--pencil .pencil,
.has--pencil .pencil-multi,
.pencil-banner {
  max-width: none;
}

@font-face {
  font-family: "Futura PT W08 Light";
  src: url("/content/themes/thestylegarage/redesign/fonts/76b4b53b-4286-43b7-8f32-1c48f7d1d55f.woff2") format("woff2"), url("/content/themes/thestylegarage/redesign/fonts/d8beb2f5-9a75-455f-95fa-58dcc6abbd4b.woff") format("woff");
}

@font-face {
  font-family: "Futura PT W01 Book";
  src: url("/content/themes/thestylegarage/redesign/fonts/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2") format("woff2"), url("/content/themes/thestylegarage/redesign/fonts/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff") format("woff");
}

@font-face {
  font-family: "Futura PT Medium Italic W08 Rg";
  src: url("/content/themes/thestylegarage/redesign/fonts/c1d40f53-6e9c-495b-93b8-89a200c1e74d.woff2") format("woff2"), url("/content/themes/thestylegarage/redesign/fonts/d6baba35-fa2e-44b7-919d-9d5d28b276e5.woff") format("woff");
}

@font-face {
  font-family: "Futura PT W08 Bold";
  src: url("/content/themes/thestylegarage/redesign/fonts/19bfa4ed-39d5-4e20-9a97-4031bcc29228.woff2") format("woff2"), url("/content/themes/thestylegarage/redesign/fonts/bc078cce-ad85-4b52-bf29-7fc88b1364b6.woff") format("woff");
}

.dev-message {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

.main-blog__text {
  margin-top: 8px;
}

/*--- Social Banner Styles */
.social-banner-container {
  display: flex;
  justify-content: center;
}

.social-text {
  display: flex;
  justify-content: flex-start;
  max-width: 1000px;
  margin: auto;
  padding-left: 10px;
  font-family: futura-pt,sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #000;
  font-size: 24px;
}

.social-text a {
  color: #008CBA;
  margin-left: 4px;
}

.myaccount-nav__link {
  background-color: #ae256f;
}

.myaccount-nav__link--selected, .myaccount-nav__link:hover {
  background-color: #7e0144;
}

.login {
  display: grid;
}

.login .login-section--login {
  grid-column: 1;
  width: auto;
}

.login .login-section--apply {
  grid-column: 2;
  background: none;
  text-align: left;
}

.login .login-section--apply a {
  margin-left: 0;
}

@media (max-width: 768px) {
  .login .login-section--apply {
    grid-column: 1;
    grid-row: 2;
  }
}

.utility-bar {
  padding: 5px;
  color: #000;
  background-color: #f8b6b7;
}

.utility-bar .social__item {
  margin: 0 4px;
}

.utility-bar .i--facebook, .utility-bar .i--instagram, .utility-bar .i--pinterest, .utility-bar .i--twitter, .utility-bar .i--youtube {
  background-color: #000;
}

.header-top__assistance {
  font-family: 'futura-pt', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.header-top__assistance a {
  color: #000;
}

.header-search {
  display: block;
  position: static;
}

.search {
  border-radius: 30px;
  overflow: hidden;
}

.search__options {
  display: none;
}

.search__text {
  border-left: none;
}

.login-links {
  color: #000;
  font-family: 'futura-pt', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.login-links__link {
  color: inherit;
  text-decoration: underline;
  text-transform: uppercase;
}

.title-wrap {
  padding-top: 0;
}

@media only screen and (min-width: 820px) {
  .title-wrap {
    padding-top: 8.25em;
  }
  .is--logged-in .title-wrap {
    padding-top: 11.45em;
  }
}

@media only screen and (min-width: 980px) {
  .title-wrap {
    padding-top: 6em;
  }
  .is--logged-in .title-wrap {
    padding-top: 9.25em;
  }
}

.account-header {
  display: block;
  padding: 0;
  color: #000;
  background-color: transparent;
}

.account-header__icon {
  border-color: #000;
}

.account-header__icon .icon-person svg {
  fill: #000;
}

.account-header__toggle svg {
  fill: #000;
}

.header-cart__value {
  color: #fff;
}

.header-cart__icon svg {
  fill: #000;
}

.account-header__nav {
  color: #fff;
}

.header-top-sites-container {
  background: #f1f1f1;
  border-bottom: 4px solid #dadbdd;
}

.header-top-flex-items {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
}

.header-top-flex-items .header-top-site-item {
  margin: 0 20px;
  font-family: "Futura PT W01 Book";
  font-size: 1.2em;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-top-flex-items .header-top-site-item a {
  color: #000;
}

.header-top-flex-items .parent-site {
  margin-left: 0;
  font-family: "Futura PT W01 Book";
}

.header-top-flex-items .parent-site a {
  font-size: 1.25em;
  padding-top: 4px;
  text-transform: uppercase;
}

.header-top-flex-items .beautyconnection-site {
  background: #dadbdd;
  padding: 0 16px;
  font-family: "Futura PT W08 Light";
  font-size: 1.6em;
}

.header-top-flex-items .beautyconnection-site a {
  color: #fff;
}

.header-top-flex-items .beautyconnection-site span {
  font-family: "Futura PT W08 Bold";
}

.header-top-flex-items .quick-registration {
  justify-self: end;
}

@media (max-width: 768px) {
  .header-top-sites-container {
    border-bottom: none;
  }
  .header-top-flex-items {
    grid-template-columns: 1fr 1fr;
  }
  .header-top-flex-items .header-top-site-item {
    text-align: center;
    margin: 0;
    border-bottom: 4px solid #EBB87A;
  }
  .header-top-flex-items .beautyconnection-site {
    text-align: center;
    margin: 0;
    grid-column: 2;
    grid-row: 1;
  }
  .header-top-flex-items .quick-registration {
    grid-column: 1 / span 2;
    justify-self: center;
    grid-row: 2;
    border-bottom: none;
    background: #F1F1F1;
    width: 100%;
    padding: 4px 0;
  }
}

.utility-bar__inner {
  display: grid;
  column-gap: 15px;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.header-top__assistance {
  grid-column: 1;
  grid-row: 1;
}

.header-search {
  grid-column: 1;
  grid-row: 2;
}

.login-links {
  grid-column: 2;
  grid-row: 1;
  justify-self: start;
}

.utility-bar .social {
  grid-column: 2;
  grid-row: 2;
}

@media only screen and (min-width: 980px) {
  .utility-bar__inner {
    grid-template-columns: repeat(1fr, 8);
  }
  .header-top__assistance {
    grid-column: 1 / span 3;
    grid-row: 1;
  }
  .header-search {
    grid-column: 6;
    grid-row: 1;
  }
  .login-links {
    grid-column: 7;
    grid-row: 1;
  }
  .utility-bar .social {
    grid-column: 8;
    grid-row: 1;
  }
}

.is--logged-in .utility-bar__inner {
  grid-template-columns: repeat(3, 1fr) auto;
}

.is--logged-in .header-search {
  grid-column: 1;
  grid-row: 3;
}

.is--logged-in .utility-bar .social {
  grid-column: 3 / span 2;
  grid-row: 3;
  justify-self: end;
}

.is--logged-in .header-cart {
  grid-column: 4;
}

.is--logged-in .header-top__assistance {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.account-header {
  grid-column: 3;
  grid-row: 1;
}

.header-top__countdown {
  grid-column: 1 / span 4;
  grid-row: 2;
  justify-self: end;
}

@media only screen and (min-width: 980px) {
  .is--logged-in .utility-bar__inner {
    grid-template-columns: repeat(5, 1fr) repeat(3, auto);
  }
  .is--logged-in .header-search {
    grid-column: 5;
    grid-row: 1;
  }
  .is--logged-in .utility-bar .social {
    grid-column: 6;
    grid-row: 1;
  }
  .is--logged-in .header-cart {
    grid-column: 8;
    margin-left: -15px;
  }
  .is--logged-in .header-top__assistance {
    grid-column: 1 / span 4;
    grid-row: 1;
  }
  .account-header {
    grid-column: 7;
    grid-row: 1;
  }
  .header-top__countdown {
    grid-column: 6 / span 3;
    grid-row: 2;
    justify-self: end;
  }
}

.header-banner {
  max-width: 100%;
  background: #000;
}

.header-banner__section {
  display: flex;
  justify-content: center;
}

.site-logo__img {
  margin: 20px auto;
}

.header-banner__section--right {
  display: none;
}

.standard-banners--tertiary {
  display: block;
}

.standard-banners--tertiary .bar {
  overflow: visible;
}

.standard-banners--tertiary .standard-banner {
  max-width: 1000px;
  margin: 15px auto;
}

.standard-banner {
  max-width: unset;
}

.main-banner-wrap .bx-prev, .main-banner-wrap .bx-next {
  display: none !important;
}

.site-nav__top {
  color: #000;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 820px) {
  .site-nav {
    border-top: none;
    border-bottom: none;
  }
}

.nav-toggles {
  background-color: #000;
}

.nav-toggle {
  background-color: transparent;
}

.bar {
  text-align: center;
  margin: 20px auto;
}

.bar > span {
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #000;
  font-size: 40px;
  text-transform: uppercase;
}

.slider-title {
  overflow: hidden;
}

.slider-caption {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.slider--products {
  margin: 40px auto;
}

.slider--products .bx-wrapper .bx-next {
  background: url("/content/themes/thestylegarage/redesign/images/new-slider-arrows_updated.png") -34px 0;
}

.slider--products .bx-wrapper .bx-prev {
  background: url("/content/themes/thestylegarage/redesign/images/new-slider-arrows_updated.png");
}

.slider--products .bx-wrapper .bx-controls-direction a {
  width: 34px;
  height: 34px;
}

.slider--products .bx-wrapper .bx-pager {
  display: none;
}

.home-article__title {
  color: #000;
  text-align: center;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.home-article__link--read,
.home-article__teaser {
  display: none;
}

@media (max-width: 480px) {
  .bar span {
    font-size: 28px;
  }
}

.site-footer__header {
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  font-size: 20px;
}

.i--facebook,
.i--instagram,
.i--twitter,
.i--pinterest,
.i--youtube {
  background-color: #f8b6b7;
}

.site-footer a, .site-footer__copyright small {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  font-size: 16px;
}

#footer {
  border-top: 24px solid #f8b6b7;
  background: #000;
}

.samples__toggle {
  background: none;
  background-color: #C39CB9;
  color: #fff;
}

.samples__toggle--svg-container {
  background-color: #8e4e7c;
}
