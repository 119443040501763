// .is--logged-in {
// 	.title-wrap {
// 		padding-top: 6em;
// 	}
// }

.header-banner {
	max-width: 100%;
	background: #000;
}

.header-banner__section {
	display: flex;
	justify-content: center;
}

.site-logo__img {
	margin: 20px auto;
}

.header-banner__section--right {
	display: none;
}

// @media (max-width: 480px) {
// 	.is--logged-in {
// 		.title-wrap {
// 			padding-top: 0;
// 		}
// 	}
// }
