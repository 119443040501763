@font-face {
	font-family:"Futura PT W08 Light";
	src: url("/content/themes/thestylegarage/redesign/fonts/76b4b53b-4286-43b7-8f32-1c48f7d1d55f.woff2") format("woff2"),
		 url("/content/themes/thestylegarage/redesign/fonts/d8beb2f5-9a75-455f-95fa-58dcc6abbd4b.woff") format("woff");
}

@font-face {
	font-family:"Futura PT W01 Book";
	src: url("/content/themes/thestylegarage/redesign/fonts/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2") format("woff2"),
		 url("/content/themes/thestylegarage/redesign/fonts/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff") format("woff");
}

@font-face {
	font-family:"Futura PT Medium Italic W08 Rg";
	src: url("/content/themes/thestylegarage/redesign/fonts/c1d40f53-6e9c-495b-93b8-89a200c1e74d.woff2") format("woff2"),
		 url("/content/themes/thestylegarage/redesign/fonts/d6baba35-fa2e-44b7-919d-9d5d28b276e5.woff") format("woff");
}

@font-face {
	font-family:"Futura PT W08 Bold";
	src: url("/content/themes/thestylegarage/redesign/fonts/19bfa4ed-39d5-4e20-9a97-4031bcc29228.woff2") format("woff2"),
		 url("/content/themes/thestylegarage/redesign/fonts/bc078cce-ad85-4b52-bf29-7fc88b1364b6.woff") format("woff");
}
