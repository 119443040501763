// 1.) Basic Styles
// --- Utility Bar
.utility-bar {
	padding: 5px;
	color: #000;
	background-color: #f8b6b7;
}

// --- Social Items
.utility-bar {
	.social__item {
		margin: 0 4px;
	}

	.i--facebook, .i--instagram, .i--pinterest, .i--twitter, .i--youtube {
		background-color: #000;
	}
}

// --- Assistance
.header-top__assistance {
	font-family: 'futura-pt', sans-serif;
	font-size: 16px;
	font-weight: 600;

	a {
		color: #000;
	}
}

// --- Search
.header-search {
	display: block;
	position: static;
}

.search {
	border-radius: 30px;
	overflow: hidden;
}

.search__options {
	display: none;
}

.search__text {
	border-left: none;
}

// --- Account Links
.login-links {
	color: #000;
	font-family: 'futura-pt', sans-serif;
	font-size: 16px;
	font-weight: 600;
}

.login-links__link {
	color: inherit;
	text-decoration: underline;
	text-transform: uppercase;
}

// --- Title Wrap
.title-wrap {
	padding-top: 0;
}

@media only screen and (min-width: 820px) {
	.title-wrap {
		padding-top: 8.25em;
	}

	.is--logged-in {
		.title-wrap {
			padding-top: 11.45em;
		}
	}
}

@media only screen and (min-width: 980px) {
	.title-wrap {
		padding-top: 6em;
	}

	.is--logged-in {
		.title-wrap {
			padding-top: 9.25em;
		}
	}
}

// --- Account Header
.account-header {
	display: block;
	padding: 0;
	color: #000;
	background-color: transparent;
}

.account-header__icon {
	border-color: #000;

	.icon-person svg {
		fill: #000;
	}
}

.account-header__toggle {
	svg {
		fill: #000;
	}
}

// --- Header Cart
.header-cart__value {
	color: #fff;
}

.header-cart__icon {
	svg {
		fill: #000;
	}
}

// --- Account Nav
.account-header__nav {
	color: #fff;
}

// --- Beauty Connection Nav
.header-top-sites-container {
	background: #f1f1f1;
	border-bottom: 4px solid #dadbdd;
}

.header-top-flex-items {
	max-width: 1000px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: auto auto 1fr;
	align-items: center;

	.header-top-site-item {
		margin: 0 20px;
		font-family: $font-regular;
		font-size: 1.2em;
		cursor: pointer;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		a {color: #000;}
	}

	.parent-site {
		margin-left: 0;
		font-family: $font-regular;
		a {
			font-size: 1.25em;
			padding-top: 4px;
			text-transform: uppercase;
		}
	}

	.beautyconnection-site {
		background: #dadbdd;
		padding: 0 16px;
		font-family: $font-thin;
		font-size: 1.6em;
		a {
			color: #fff;
		}

		span {
      font-family: $font-bold;
		}
	}

	.quick-registration {
		justify-self: end;
	}
}

@media (max-width: 768px) {
  .header-top-sites-container {
		border-bottom: none;
	}

	.header-top-flex-items {
		grid-template-columns: 1fr 1fr;

		.header-top-site-item {
			text-align: center;
			margin: 0;
			border-bottom: 4px solid #EBB87A;
		}

		.beautyconnection-site {
			text-align: center;
			margin: 0;
			grid-column: 2;
			grid-row: 1;
		}

		.quick-registration {
			grid-column: 1 / span 2;
			justify-self: center;
			grid-row: 2;
			border-bottom: none;
			background: #F1F1F1;
			width: 100%;
			padding: 4px 0;
		}
	}
}


// 2.) Layout (Logged Out)
.utility-bar__inner {
	display: grid;
	column-gap: 15px;
	grid-template-columns: 1fr auto;
	align-items: center;
}

.header-top__assistance {
	grid-column: 1;
	grid-row: 1;
}

.header-search {
	grid-column: 1;
	grid-row: 2;
}

.login-links {
	grid-column: 2;
	grid-row: 1;
	justify-self: start;
}

.utility-bar {
	.social {
		grid-column: 2;
		grid-row: 2;
	}
}

@media only screen and (min-width: 980px) {
	.utility-bar__inner {
		grid-template-columns: repeat(1fr, 8);
	}

	.header-top__assistance {
		grid-column: 1 / span 3;
		grid-row: 1;
	}

	.header-search {
		grid-column: 6;
		grid-row: 1;
	}

	.login-links {
		grid-column: 7;
		grid-row: 1;
	}

	.utility-bar {
		.social {
			grid-column: 8;
			grid-row: 1;
		}
	}
}


// 3.) Layout (Logged In)
.is--logged-in {
	.utility-bar__inner {
		grid-template-columns: repeat(3, 1fr) auto;
	}

	.header-search {
		grid-column: 1;
		grid-row: 3;
	}

	.utility-bar .social {
		grid-column: 3 / span 2;
		grid-row: 3;
		justify-self: end;
	}

	.header-cart {
		grid-column: 4;
	}

	.header-top__assistance {
		grid-column: 1 / span 2;
		grid-row: 1;
	}
}

.account-header {
	grid-column: 3;
	grid-row: 1;
}

.header-top__countdown {
	grid-column: 1 / span 4;
	grid-row: 2;
	justify-self: end;
}

@media only screen and (min-width: 980px) {
	.is--logged-in {
		.utility-bar__inner {
			grid-template-columns: repeat(5, 1fr) repeat(3, auto);
		}

		.header-search {
			grid-column: 5;
			grid-row: 1;
		}

		.utility-bar .social {
			grid-column: 6;
			grid-row: 1;
		}

		.header-cart {
			grid-column: 8;
			margin-left: -15px;
		}

		.header-top__assistance {
			grid-column: 1 / span 4;
			grid-row: 1;
		}
	}

	.account-header {
		grid-column: 7;
		grid-row: 1;
	}

	.header-top__countdown {
		grid-column: 6 / span 3;
		grid-row: 2;
		justify-self: end;
	}
}
